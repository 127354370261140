import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import moment from "moment";
import store from "./store/index";
import request from "@/utils/request";
import "./assets/iconfont/iconfont";
import "./assets/iconfont/iconfont.css";
import "./style/variables.scss";
Vue.config.productionTip = false;

Vue.use(ElementUI, { size: "mini" });
Vue.prototype.$moment = moment;
Vue.prototype.$request = request;
Vue.prototype.$baseUrl = process.env.VUE_APP_BASEURL;
Vue.directive("removeAriaHidden", {
  bind(el, binding) {
    const ariaEls = el.querySelectorAll(".el-radio__original");
    ariaEls.forEach((item) => {
      item.removeAttribute("aria-hidden");
    });
  },
});
new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount("#app");
