<template>
  <div style="background: #effff1; width: 100%">
    <el-menu class="el-menu-vertical-demo" router :default-active="activeRoute">
      <el-menu-item v-for="item in routes" :key="item.path" :index="item.path">
        <i class="iconfont" style="margin-right: 10px" :class="item.meta.icon"></i>
        <span slot="title">{{ item.meta.til }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { routes } from "../router/index";
import { getCurrentInstance } from "vue";
export default {
  name: "Menu",
  data() {
    return {
      routes: [],
      activeRoute: "",
    };
  },
  created() {
    const { proxy } = getCurrentInstance();
    this.activeRoute = proxy.$router.currentRoute.fullPath;
  },
  mounted() {
      routes.forEach((i) => {
        if (i.path == "/User" && !this.$store.state.user.userInfo?.isAdmin) {
          i.meta.hidden = true;
        } else if (i.path == "/User" && this.$store.state.user.userInfo?.isAdmin) {
          i.meta.hidden = false;
        }
      });
      this.routes = routes.filter((item) => !item.meta.hidden);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.el-menu {
  border: none;
  .el-sub-menu .el-icon {
    font-size: 22px;
  }
  :deep(.el-sub-menu__title) {
    font-size: 16px;
    &:hover {
      // color: #fff;
      background: rgba(#ebf0f1, 0.6);
    }
  }
  .el-menu-item {
    font-size: 16px;
    color: #4d4e4f;
    &:hover {
      // color: #fff;
      background: rgba(#ebf0f1, 0.6);
    }
    i {
      color: #4d4e4f;
    }
  }
  .el-menu-item.is-active {
    // color: white;
    background: rgba(#ebf0f1, 0.6);
    border-right: 5px solid #4ad089;
    // color: #4094ff;
    // i {
    //   color: #4094ff;
    // }
  }
  // .el-menu .el-menu--popup {
  //   margin-left: -140px;
  // }
}
</style>
