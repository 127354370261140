import router from "@/router";
import store from "@/store";
import axios from "axios";
import { Message } from "element-ui";
// const url = "http://172.16.23.224"; //测试
// const url = "/api";
const url = "https://mold.chohogroup.com/api"; //正式环境
const request = axios.create({
  baseURL: url,
  timeout: 600000,
  retry: 3, //设置全局重试请求次数
  retryDelay: 1000, //设置全局请求间隔
});
//请求拦截器
request.interceptors.request.use(
  function (config) {
    // console.log(config.headers, "请求");
    // console.log(store, "store");
    let token = store.state.user.token;
    let refresh = store.state.user.refresh;
    config.headers["accessToken"] = token;
    config.headers["refreshToken"] = refresh;
    return config; // config
  },
  function (error) {
    //请求错误时做些事
    // console.log(error, "请求错误");
    return Promise.reject(error);
  }
);
//响应拦截器
request.interceptors.response.use(
  async function (res) {
    if (res.headers?.accesstoken) {
      //存返回的token
      store.commit("user/saveToken", res.headers.accesstoken);
      //给headers里面加上请求头
      res.config.headers["accessToken"] = res.headers.accesstoken;
    }
    if (res.headers?.refreshtoken) {
      store.commit("user/saveRefresh", res.headers.refreshtoken);
      res.config.headers["refreshToken"] = res.headers.refreshtoken;
    }
    if (res.data?.code != "200") {
      if (res.data.msg) {
        Message.error({
          message: res.data.msg,
          center: true,
        });
      }
      if (res.data?.code == "300") {
        store.commit("user/logOut");
        await router.push({
          path: "/login",
        });
      }
    }
    return res;
  },
  function (error) {
    //请求错误时做些事
    // console.log(error, "err");
    // 异常处理
    if (error.response && error.response.status === 500) {
      // 跳转到登录页面
      router.push("/login");
    } else {
      // 其他异常处理
      Message.error({
        message: "请求出错，请稍后重试",
        center: true,
      });
    }
    return Promise.reject(error);
  }
);
export default request;
