<template>
  <div class="container">
    <img style="position: sticky; right: 0; left: 0" src="../../assets/images/CHOHO.png" width="15%" alt="" />
    <div class="login-box">
      <div class="image-container">
        <img style="width: 90%" src="../../assets/images/left2.png" alt="Login Image" />
      </div>
      <div class="form-container">
        <el-form :model="user" class="login-form" :rules="rules" ref="loginRef">
          <div class="form-title">征和工业模具扫码后台管理系统</div>
          <el-form-item prop="username">
            <el-input placeholder="请输入账号" prefix-icon="el-icon-user" v-model="user.username"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input show-password placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="user.password"></el-input>
          </el-form-item>
          <!-- <el-form-item prop="code">
            <div style="display: flex">
              <el-input placeholder="请输入验证码" prefix-icon="el-icon-circle-check" size="medium" style="flex: 1" v-model="user.code"></el-input>
              <div style="flex: 1; height: 36px">
                <valid-code @update:value="getCode"/>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item>
            <el-checkbox v-model="remember" label="记住我" />
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100%" type="success" @click="login">点击登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import ValidCode from "@/components/ValidCode";
import { md5 } from "js-md5";
import { userLogin } from "./api";
import store from "@/store";
import CryptoJS from "crypto-js";
export default {
  name: "Login",
  components: {
    ValidCode,
  },
  data() {
    // 验证码校验
    const validateCode = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入验证码"));
      } else if (value.toLowerCase() != this.code) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };

    return {
      forgetUserForm: {},
      forgetPassDialogVis: false,
      formLabelWidth: "80px",
      code: "", // 验证码组件传递过来的code
      user: {
        username: "",
        password: "",
      },
      remember: false,
      rules: {
        // 校验表单
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        code: [{ validator: validateCode, trigger: "blur" }],
      },
    };
  },
  mounted() {
    //绑定监听事件
    window.addEventListener("keydown", this.keyDown);
    //判断本地有没有用户名密码
    const info = JSON.parse(window.localStorage.getItem("moldInfo")) || {};
    if (typeof info?.username == "string") {
      this.remember = true;
      this.user.username = info.username;
      this.user.password = CryptoJS.AES.decrypt(info.password, "secretkey").toString(CryptoJS.enc.Utf8);
    } else {
      this.remember = false;
    }
  },
  destroyed() {
    //销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    getCode(code) {
      this.code = code.toLowerCase();
    },
    //点击回车登录
    keyDown(e) {
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.login();
      }
    },
    //记住密码
    saveInfo() {
      if (this.remember) {
        window.localStorage.setItem(
          "moldInfo",
          JSON.stringify({
            username: this.user.username,
            password: CryptoJS.AES.encrypt(this.user.password, "secretkey").toString(),
          })
        );
      } else {
        window.localStorage.removeItem("moldInfo");
      }
    },
    login() {
      this.$refs["loginRef"].validate(async (valid) => {
        if (valid) {
          let res = await userLogin({ ...this.user, password: md5(this.user.password) });
          // console.log(res, "login");
          if (res.data.code == "200") {
            this.$message({
              type: "success",
              center: true,
              message: "登录成功",
            });
            // 存token
            store.commit("user/saveToken", res.data.data.token);
            store.commit("user/saveRefresh", res.data.data.refresh);
            //存用户信息
            store.commit("user/saveUserInfo", res.data.data);
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
            this.saveInfo();
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* background-color: #98f1ee; */
  background: url("../../assets/images/beijing1.png") no-repeat center;
  background-size: cover;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
}
.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display: flex;
  background-color: white;
  width: 60%;
  height: 50%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #4ad089;
}
:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background: #4ad089;
  border-color: #4ad089;
}
.image-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #B6FFD3; */
}
.image-container img {
  object-fit: cover;
}
.form-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  width: 80%;
}
.form-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.valid-code-container {
  display: flex;
}
.valid-code-input {
  flex: 1;
}
.valid-code-text {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  margin-left: 10px;
}
.footer-text {
  display: flex;
}
.register-text {
  flex: 1;
}
.register-link {
  color: #98f1ee;
  cursor: pointer;
}
.forgot-password-text {
  flex: 1;
  text-align: right;
}
</style>
